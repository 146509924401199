import I18n from '@/src/global/utils/i18n-translations';
import { Controller } from '@hotwired/stimulus';

// changes the placeholder of the input field
// every n seconds
export default class extends Controller {
  connect() {
    const placeholders = [
      I18n.t('dynamic_search_placeholder.one'),
      I18n.t('dynamic_search_placeholder.two'),
      I18n.t('dynamic_search_placeholder.three')
    ];
    let index = 0;

    setInterval(() => {
      this.element.setAttribute('placeholder', placeholders[index]);

      index = (index + 1) % placeholders.length;
    }, 3000);
  }
}
